import React from 'react';

const categories = [
    { id: 1, name: 'Cash Hunts', image: 'https://img.icons8.com/color/96/000000/money-bag.png' },
    { id: 8, name: 'Dining', image: 'https://img.icons8.com/color/96/000000/cafe.png' },
    { id: 8, name: 'Education', image: 'https://img.icons8.com/color/96/000000/school.png' },
    { id: 6, name: 'Fashion', image: 'https://img.icons8.com/color/96/000000/wardrobe.png' },
    { id: 2, name: 'Food Blogs', image: 'https://img.icons8.com/color/96/000000/dining-room.png' },
    { id: 9, name: 'Health and Fitness', image: 'https://img.icons8.com/color/96/000000/heart-health.png' },
    { id: 10, name: 'News', image: 'https://img.icons8.com/color/96/000000/news.png' }, // New category
    { id: 3, name: 'Real Estate', image: 'https://img.icons8.com/color/96/000000/real-estate.png' },

    // { id: 4, name: 'Animals', image: 'https://img.icons8.com/color/48/dog.png' },
    // { id: 5, name: 'Room Rent', image: 'https://img.icons8.com/color/96/000000/room.png' },
    // { id: 6, name: 'Travel', image: 'https://img.icons8.com/color/96/000000/suitcase.png' },

    { id: 7, name: 'Shop', image: 'https://img.icons8.com/color/96/000000/shop.png' },



    { id: '11', name: 'Vehicle', image: 'https://img.icons8.com/color/96/000000/car.png' } // New category

];

const CategorySection = () => {
    return (
        <section id={"categories"} className=" flex flex-col items-center justify-center text-white py-16">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-12">
                    <h2 className="text-4xl font-bold mb-6">Explore Content Categories</h2>
                    <p className="text-xl max-w-2xl mx-auto text-blue-100">
                        Discover a range of categories featuring city influencers and content creators, tailored to meet your business needs.
                    </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-6 gap-10 md:gap-14">
                    {categories.map((category) => (
                        <div
                            key={category.id}
                            className="flex flex-col items-center justify-center transition-transform duration-300 hover:scale-105"
                        >
                            <img
                                src={category.image}
                                alt={category.name}
                                width={80}
                                height={80}
                                className="w-20 h-20 object-contain mb-4 opacity-80 hover:opacity-100"
                            />
                            <h3 className="text-lg font-semibold">{category.name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CategorySection;
