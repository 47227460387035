import { Users, DollarSign, Share2, Loader2 } from 'lucide-react';
import { useState } from 'react';

const SubmitSection = ({
                           selectedCreators,
                           selectedCollaborations,
                           influencerData,
                           samplePages,
                           isSubmitting,
                           submitStatus
                       }) => {

    const getSelectedDetails = () => {
        const selectedCreatorsData = influencerData.filter(creator =>
            selectedCreators.includes(creator.id)
        );
        const selectedCollabsData = samplePages.filter(collab =>
            selectedCollaborations.includes(collab.id)
        );

        return {
            totalCharges: selectedCreatorsData.reduce((sum, creator) =>
                sum + (Number(creator.charges) || 0), 0
            ) + selectedCollabsData.reduce((sum, collab) =>
                sum + (Number(collab.charges) || 0), 0
            ),
            totalSelected: selectedCreatorsData.length + selectedCollabsData.length,
            creators: selectedCreatorsData,
            collabs: selectedCollabsData
        };
    };

    const details = getSelectedDetails();

    // Animation classes for the cards
    const cardClasses = `bg-white p-6 rounded-xl border shadow-sm hover:shadow-md transition-all duration-300 ${
        isSubmitting ? 'opacity-50' : ''
    }`;

    // Animation classes for the profiles container
    const profilesContainerClasses = `bg-white p-4 rounded-xl border border-gray-200 transition-all duration-300 ${
        isSubmitting ? 'opacity-50' : ''
    }`;

    // Button states and classes
    const getButtonContent = () => {
        if (details.totalSelected === 0) {
            return {
                text: 'Select creators or collaborations to continue',
                classes: 'bg-gray-400 cursor-not-allowed'
            };
        }

        if (isSubmitting) {
            return {
                text: 'Submitting Request...',
                classes: 'bg-[#e310cb] cursor-wait'
            };
        }

        return {
            text: 'Submit Collaboration Request',
            classes: 'bg-[#e310cb] hover:bg-[#d00cb8] cursor-pointer'
        };
    };

    const buttonContent = getButtonContent();

    return (
        <div className="border-t mt-8 pt-8 w-full space-y-6">
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className={cardClasses}>
                    <div className="flex justify-between">
                        <div>
                            <div className="text-base text-gray-500">Selected Items</div>
                            <div className="text-3xl text-gray-900 mt-2">{details.totalSelected}</div>
                            <div className="text-sm text-gray-600 mt-2">
                                {details.creators.length} Creators • {details.collabs.length} Collaborations
                            </div>
                        </div>
                        <div className="h-12 w-12 bg-purple-100 rounded-full flex items-center justify-center">
                            <Users size={24} className="text-purple-600" />
                        </div>
                    </div>
                </div>

                <div className={cardClasses}>
                    <div className="flex justify-between">
                        <div>
                            <div className="text-base text-gray-500">Total Investment</div>
                            <div className="text-3xl text-gray-900 mt-2">₹{details.totalCharges.toLocaleString()}</div>
                            <div className="text-sm text-gray-600 mt-2">All charges included</div>
                        </div>
                        <div className="h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                            <DollarSign size={24} className="text-green-600" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Selected Items Preview */}
            {details.totalSelected > 0 && (
                <div className={profilesContainerClasses}>
                    <div className="text-base text-gray-700 mb-3">Selected Profiles</div>
                    <div className="flex flex-wrap gap-3">
                        {[...details.creators, ...details.collabs].map(item => (
                            <div
                                key={item.id}
                                className="flex-none transition-all duration-300"
                            >
                                <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-full border border-gray-300">
                                    <img src={item.profilePicture} alt={item.name} className="w-8 h-8 rounded-full" />
                                    <span className="text-sm text-gray-900">{item.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Submit Button */}
            <button
                type="submit"
                disabled={details.totalSelected === 0 || isSubmitting}
                className={`flex justify-between items-center py-4 px-6 rounded-lg text-lg font-semibold text-white transition-all duration-300 ${buttonContent.classes}`}
                style={{ width: 'calc(100% - 0px)', boxSizing: 'border-box', zIndex: 10 }}
            >
                <div className="flex items-center gap-2">
                    {isSubmitting ? (
                        <Loader2 size={24} className="animate-spin" />
                    ) : (
                        <Share2 size={24} />
                    )}
                    <span>{buttonContent.text}</span>
                </div>
                {!isSubmitting && (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                )}
            </button>
        </div>
    );
};

export default SubmitSection;