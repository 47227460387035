import React, { useState } from 'react';
import ashish from '../image/ashish.png';
import gourav from '../image/gourav.jpeg';
import atharv from '../image/atharv.jpg';

// Default placeholder image for members without a profile image
const defaultImage = 'https://via.placeholder.com/64'; // Placeholder for missing images

const teamMembers = [
    { id: 1, name: 'Gourav Chouhan', role: 'Indore Head', location: 'Indore', image: gourav },
    { id: 2, name: 'Atharv Barkiya', role: 'CEO', location: 'Bangalore', image: atharv },
    { id: 3, name: 'Vishwash', role: 'CFO', location: 'Texas', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fvishwash.jpg?alt=media&token=3d43357b-4bec-4aeb-b765-15c9da234f11' },
    { id: 4, name: 'Yogendra Bamne', role: 'Email Marketing', location: 'Indore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' }, // Default image
    { id: 5, name: 'Ashish Patidar', role: 'Call Marketer', location: 'Bangalore', image: ashish },
    { id: 6, name: 'Gourav Chouhan', role: 'Social Media Manager', location: 'Indore', image: gourav },
    { id: 7, name: 'Sami', role: 'Developer', location: 'Bangalore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Finstagram-profile-icon_772860-1198.avif?alt=media&token=e250e7f0-789e-425a-a608-9200d4ee193a' },
    { id: 8, name: 'Yogendra Bamne', role: 'Whatsapp Marketing', location: 'Indore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' },
    { id: 9, name: 'Gourav Chouhan', role: 'Indore Head', location: 'Indore', image: gourav },
    { id: 10, name: 'Atharv Barkiya', role: 'CEO', location: 'Bangalore', image: atharv },
    { id: 11, name: 'Vishwash', role: 'CFO', location: 'Texas', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fvishwash.jpg?alt=media&token=3d43357b-4bec-4aeb-b765-15c9da234f11' },
    { id: 12, name: 'Yogendra Bamne', role: 'Email Marketing', location: 'Indore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' }, // Default image
    { id: 13, name: 'Ashish Patidar', role: 'Call Marketer', location: 'Bangalore', image: ashish },
    { id: 14, name: 'Gourav Chouhan', role: 'Social Media Manager', location: 'Indore', image: gourav },
    { id: 15, name: 'Sami', role: 'Developer', location: 'Bangalore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Finstagram-profile-icon_772860-1198.avif?alt=media&token=e250e7f0-789e-425a-a608-9200d4ee193a' },
    { id: 16, name: 'Yogendra Bamne', role: 'Whatsapp Marketing', location: 'India', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' },
    { id: 17, name: 'Gourav Chouhan', role: 'Indore Head', location: 'Indore', image: gourav },
    { id: 18, name: 'Atharv Barkiya', role: 'CEO', location: 'Bangalore', image: atharv },
    { id: 19, name: 'Vishwash', role: 'CFO', location: 'Texas', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fvishwash.jpg?alt=media&token=3d43357b-4bec-4aeb-b765-15c9da234f11' },
    { id: 20, name: 'Yogendra Bamne', role: 'Email Marketing', location: 'Indore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' }, // Default image
    { id: 21, name: 'Ashish Patidar', role: 'Call Marketer', location: 'Bangalore', image: ashish },
    { id: 22, name: 'Gourav Chouhan', role: 'Social Media Manager', location: 'Indore', image: gourav },
    { id: 23, name: 'Sami', role: 'Developer', location: 'Bangalore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Finstagram-profile-icon_772860-1198.avif?alt=media&token=e250e7f0-789e-425a-a608-9200d4ee193a' },
    { id: 24, name: 'Yogendra Bamne', role: 'Whatsapp Marketing', location: 'Indore', image: 'https://firebasestorage.googleapis.com/v0/b/consaiouswebsite.appspot.com/o/Dubbigo%2Fyogendra.jpg?alt=media&token=ea785204-82ae-4ae0-a499-009b2ac37537' },
    { id: 25, name: 'Gourav Chouhan', role: 'Indore Head', location: 'Indore', image: gourav },
];

const TeamMember = ({ member }) => (
    <div className="team-member bg-white rounded-lg shadow-md p-4 flex items-center space-x-4">
        <div className="profile-pic flex-shrink-0">
            <img src={member.image || defaultImage} alt={member.name} className="w-16 h-16 rounded-full object-cover" />
        </div>
        <div className="member-info text-left">
            <h3 className="text-lg font-semibold text-gray-800">{member.name}</h3>
            <p className="text-sm text-gray-600">{member.role}</p>
            <p className="text-xs text-gray-500">{member.location}</p>
        </div>
    </div>
);

const ScrollingRow = ({ members, direction, speed }) => {
    const [isPaused, setIsPaused] = useState(false);

    return (
        <div
            className={`scrolling-row ${direction} ${isPaused ? 'paused' : ''} overflow-hidden my-8`}
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <div
                className="scroll-content flex"
                style={{ animationDuration: `${speed}s` }}
            >
                {members.concat(members).map((member, index) => (
                    <div key={`${member.id}-${index}`} className="flex-shrink-0 mx-4">
                        <TeamMember member={member} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const TeamSection = () => {
    const rowMembers = [
        teamMembers.slice(0, 7),
        teamMembers.slice(8, 16),
        teamMembers.slice(17, 25),
    ];
    // section-plum-faq
    return (
        <section className=" text-white py-16 px-4" id="team">
            <div className="mx-auto">
                <h1 className="text-4xl font-bold text-center mb-6">Meet Our Team</h1>
                <p className="text-center text-xl mb-12 max-w-2xl mx-auto text-blue-100">
                    Behind our platform is a dedicated team bridging the gap between top city Creator and brands, ensuring seamless collaborations and impactful campaigns.
                </p>
                <ScrollingRow members={rowMembers[0]} direction="right-to-left" speed={75} />
                <ScrollingRow members={rowMembers[1]} direction="left-to-right" speed={70} />
                <ScrollingRow members={rowMembers[2]} direction="right-to-left" speed={85} />
            </div>
        </section>
    );

};

export default TeamSection;
