import React, { useState } from 'react';
import { Plus, Trash2, CheckCircle2, Check, Loader2    } from 'lucide-react';
import '../component/CollaborationForm.css';
import { ChevronRight, Minus } from 'lucide-react';


const ContentCreatorForm = ({  onCategorySelect }) => {
    const [formData, setFormData] = useState({
        name: '',
        pageLink: '',
        phoneNumber: '',
        location: '',
        role: [],
        categories: [{ category: '', charges: '', demoVideos: ['', ''] }],
    });

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for loading

    const locations = ['Indore', 'Bhopal', 'Ujjain', 'Gwalior'];

    const roles = [
        {
            id: 'content_creator',
            title: 'Content Creator',
            description: 'Share your content and set your rates',
            icon: <CheckCircle2 className="text-white" size={24} />
        },
        {
            id: 'collaborator',
            title: 'Collaborator',
            description: 'Find and collab with creators',
            icon: <CheckCircle2 className="text-white" size={24} />
        }
    ];

    const categories = [
        { id: 'cash-hunt', name: 'Cash Hunts', label: 'Cash Hunt', image: 'https://img.icons8.com/color/96/000000/money-bag.png' },
        { id: 'dining', name: 'Dining', label: 'Dining', image: 'https://img.icons8.com/color/48/cafe.png' },

        { id: 'education', name: 'Education', image: 'https://img.icons8.com/color/96/000000/school.png' },
        { id: 'fashion', name: 'Fashion', label: 'Fashion', image: 'https://img.icons8.com/color/96/000000/wardrobe.png' },
        { id: 'fitness', name: 'Fitness', image: 'https://img.icons8.com/color/96/000000/heart-health.png' },
        { id: 'food-blog', name: 'Food Blogs', label: 'Food Blog', image: 'https://img.icons8.com/color/96/000000/dining-room.png' },
        { id: 'news', name: 'News', image: 'https://img.icons8.com/color/96/000000/news.png' },
        { id: 'real-estate', name: 'Real Estate', label: 'Real Estate', image: 'https://img.icons8.com/color/96/000000/real-estate.png' },
        // { id: 'travel', name: 'Travel', label: 'Travel', image: 'https://img.icons8.com/color/96/000000/suitcase.png' },
        // { id: 'room-rent', name: 'Room Rent', label: 'Room Rent', image: 'https://img.icons8.com/color/96/000000/room.png' },

        // { id: 'animal', name: 'Animals', label: 'Animal', image: 'https://img.icons8.com/color/48/dog.png' },



        { id: 'shop', name: 'Shop', label: 'Shop', image: 'https://img.icons8.com/color/96/000000/shop.png' },

        { id: 'vehicle', name: 'Vehicle', image: 'https://img.icons8.com/color/96/000000/car.png' } // New category

    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRoleChange = (role) => {
        setFormData(prev => ({
            ...prev,
            role: prev.role.includes(role)
                ? prev.role.filter(r => r !== role)
                : [...prev.role, role]
        }));
        // Show form if at least one role is selected
        setShowForm(prev => prev || !formData.role.includes(role));
    };

    const addCategory = () => {
        setFormData(prev => ({
            ...prev,
            categories: [...prev.categories, { category: '', charges: '', demoVideos: ['', ''] }]
        }));
    };

    const removeCategory = (index) => {
        setFormData(prev => ({
            ...prev,
            categories: prev.categories.filter((_, i) => i !== index)
        }));
    };

    const updateCategory = (index, field, value, videoIndex = null) => {
        setFormData(prev => ({
            ...prev,
            categories: prev.categories.map((cat, i) => {
                if (i === index) {
                    if (videoIndex !== null) {
                        const newDemoVideos = [...cat.demoVideos];
                        newDemoVideos[videoIndex] = value;
                        return { ...cat, demoVideos: newDemoVideos };
                    }
                    return { ...cat, [field]: value };
                }
                return cat;
            })
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!acceptedTerms) {
            alert('Please accept the terms and conditions');
            return;
        }

        // Set loading state to true before submission
        setIsSubmitting(true);

        // Prepare query parameters
        const params = new URLSearchParams({
            name: formData.name,
            pageLink: formData.pageLink,
            phoneNumber: formData.phoneNumber,
            location: formData.location,
            role: formData.role.join(','),
            categories: JSON.stringify(formData.categories),
            selectedCategories: JSON.stringify(selectedCategories)
        });

        try {
            const response = await fetch(`https://script.google.com/macros/s/AKfycbyv7zbdsVnUWSTHH_8DePEHSZhn_mZUKuNzGtJsop7P3DiXLbxNDW-WSPuqW5BtYZK9LA/exec?${params}`, {
                method: 'GET'
            });

            const result = await response.json();

            if (result.status === 'success') {
                alert('Submission successful!');
            } else {
                alert('Submission failed: ' + result.message);
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert('Submission failed. Please try again.');
        } finally {
            // Set loading state to false after submission (success or failure)
            setIsSubmitting(false);
        }
    };






    const [selectedCategories, setSelectedCategories] = useState([]);

    const toggleCategory = (category) => {
        setSelectedCategories(prev => {
            const exists = prev.find(c => c.id === category.id);
            if (exists) {
                return prev.filter(c => c.id !== category.id);
            }
            return [...prev, { ...category, price: '' }];
        });
    };

    const updateCategoryPrice = (categoryId, price) => {
        setSelectedCategories(prev =>
            prev.map(cat =>
                cat.id === categoryId ? { ...cat, price } : cat
            )
        );
    };

    return (
        <div className="h-screen flex flex-col">
            {/* Navbar */}
            <nav className="sticky top-0  text-white p-4 shadow-md z-10 fixed-navbar ">
                <ul className="flex items-center ">
                    <li className="navbar-item logo">
                        <h1>
                            <a href="/" className="logo-link">
                                <img src="/newlogo.png" alt="DubbiGo Logo" className="logo-image"/>
                                <a>ubbiGo</a>
                            </a>
                        </h1>
                    </li>
                </ul>
            </nav>


            {/* Main Content */}
            <div className="flex-grow overflow-y-auto bg-gradient-to-br from-[#0d314b] via-[#193a55] to-[#1c4b65] p-6">
                <div className="max-w-4xl mx-auto p-6">



                    {/*Step indicator*/}
                    <div className="mb-8">
                        <div className="flex items-center justify-center mb-4">
                            <div className={`h-3 w-3 rounded-full ${formData.role.length ? 'bg-[#e310cb]' : 'bg-gray-300'}`}></div>
                            <div className={`h-1 w-16 ${showForm ? 'bg-[#e310cb]' : 'bg-gray-300'}`}></div>
                            <div className={`h-3 w-3 rounded-full ${showForm ? 'bg-[#e310cb]' : 'bg-gray-300'}`}></div>
                            <div className={`h-1 w-16 ${acceptedTerms ? 'bg-[#e310cb]' : 'bg-gray-300'}`}></div>
                            <div className={`h-3 w-3 rounded-full ${acceptedTerms ? 'bg-[#e310cb]' : 'bg-gray-300'}`}></div>
                        </div>
                        <div className="text-center text-white text-sm mb-8">
                            {!formData.role.length ? 'Step 1: Choose your role' :
                                !showForm ? 'Step 2: Fill your details' :
                                    !acceptedTerms ? 'Step 3: Accept terms' : 'Ready to submit!'}
                        </div>
                    </div>




                    {/* Role Selection Section */}
                    <div className="bg-white rounded-lg p-6 mb-6">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
                            Choose Your Role(s)
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {roles.map(role => (
                                <div
                                    key={role.id}
                                    onClick={() => handleRoleChange(role.id)}
                                    className={`p-8 rounded-lg border-2 transition-all cursor-pointer transform hover:scale-105
                                        ${formData.role.includes(role.id)
                                        ? 'border-[#e310cb] bg-purple-50 shadow-lg'
                                        : 'border-gray-200 hover:border-[#e310cb] hover:shadow-md'}`}
                                >
                                    <div className="flex flex-col items-center text-center gap-4">
                                        <div className="relative w-16 h-16 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center">
                                            {role.icon}
                                            {formData.role.includes(role.id) && (
                                                <div className="absolute -bottom-2 -right-2 bg-green-500 rounded-full p-1">
                                                    <Check size={16} className="text-white" />
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-900 mb-2">{role.title}</h3>
                                            <p className="text-gray-600">{role.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>



                    {/* Show form only after role selection */}
                    {showForm && (
                        <>
                            {/* Terms and conditions section */}
                            {/* Terms and Conditions Section */}
                            <div className="bg-white border border-gray-200 hover:border-purple-400 rounded-lg p-4 mt-4 mb-8 transition-all duration-300 hover:shadow-lg">
                                {/* Header Section */}
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center shadow-md">
                                        <i className="fas fa-file-alt text-white text-lg"></i>
                                    </div>
                                    <div>
                                        <h2 className="text-2xl font-bold text-gray-800 hover:text-[#e310cb] transition-colors duration-200">
                                            Terms & Conditions
                                        </h2>
                                        <p className="text-sm text-gray-500">
                                            Please read carefully before joining as a content creator or collaborator.
                                        </p>
                                    </div>
                                </div>

                                {/* Highlighted Information */}
                                <div className="bg-purple-50 hover:bg-purple-100 rounded-lg p-4 mb-4 transition-colors duration-200 border border-purple-300 shadow-sm">
                                    <p className="text-sm text-gray-700 font-medium">
                                        By registering, you agree to follow professional standards, adhere to community guidelines, and meet deadlines for collaborations and content creation.
                                    </p>
                                </div>

                                {/* Agreement Points */}
                                <ul className="space-y-3">
                                    {/* Original Guidelines */}
                                    {[
                                        "We connect creators with businesses based on their niche; therefore, maintaining an updated and professional profile is essential.",
                                        "Content creators can add their own content creation or collaboration charges, along with demo videos, for businesses to review.",
                                        "Dubbigo will charge a 5% fee from the total bill amount for platform services.",
                                    ].map((text, index) => (
                                        <li
                                            key={index}
                                            className="flex items-start gap-2 group hover:bg-purple-50 p-2 rounded-lg transition-colors duration-200"
                                        >
                                            <div className="w-5 h-5 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center flex-shrink-0 mt-0.5 group-hover:shadow-md transition-shadow duration-200">
                                                <i className="fas fa-check text-white text-xs"></i>
                                            </div>
                                            <span className="text-sm text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                    {text}
                </span>
                                        </li>
                                    ))}

                                    {/* New Points */}
                                    {[
                                        "Our team will respond to collaboration or support requests within 24 hours.",
                                        "Content creators must respond promptly to promotional opportunities secured by our team to ensure timely execution.",
                                    ].map((text, index) => (
                                        <li
                                            key={index + 5}
                                            className="flex items-start gap-2 group hover:bg-purple-50 p-2 rounded-lg transition-colors duration-200"
                                        >
                                            <div className="w-5 h-5 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center flex-shrink-0 mt-0.5 group-hover:shadow-md transition-shadow duration-200">
                                                <i className="fas fa-check text-white text-xs"></i>
                                            </div>
                                            <span className="text-sm text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                    {text}
                </span>
                                        </li>
                                    ))}
                                </ul>

                            {/* Acceptance Section */}
                                <div className="mt-4 pt-3 border-t border-gray-100 group hover:border-purple-200 transition-colors duration-200">
                                    <label className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            checked={acceptedTerms}
                                            onChange={(e) => setAcceptedTerms(e.target.checked)}
                                            className="rounded text-purple-600 w-4 h-4"
                                        />
                                        <span className="text-sm text-gray-700">I accept the terms and conditions and agree to comply with the guidelines.</span>
                                    </label>
                                </div>
                            </div>



                            {/* Keep existing terms section */}



                            {/* Registration form */}
                            {/* Registration Form */}
                            <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg overflow-hidden">
                                <div className="bg-gradient-to-r from-[#4b0d49] to-[#e310cb] p-6">
                                    <h1 className="text-2xl font-bold text-white">Content Creator Registration</h1>
                                    <p className="text-purple-100">Join our creative community</p>
                                </div>

                                <div className="p-6 space-y-6">
                                    {/* Basic Information */}
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label htmlFor="pageName" className="block text-sm font-medium text-gray-700 mb-1 ">
                                                Page Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                                placeholder={"Your Page Name"}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="pageLink" className="block text-sm font-medium text-gray-700 mb-1">
                                                Page Link
                                            </label>
                                            <input
                                                type="text"
                                                id="pageLink"
                                                name="pageLink"
                                                value={formData.pageLink}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                                placeholder="instagram.com/yourpage"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phoneNumber"
                                                   className="block text-sm font-medium text-gray-700 mb-1">
                                                Phone Number
                                            </label>
                                            <input
                                                type="tel"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                                placeholder="+91 1234567890"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="location"
                                                   className="block text-sm font-medium text-gray-700 mb-1">
                                                Location
                                            </label>
                                            <input
                                                type="text"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-gray-900"
                                                placeholder="Enter City"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Role Selection - Improved UI */}
                                    <div className="mb-8">
                                        <h3 className="text-xl font-semibold text-gray-800 mb-6">Select Your Role</h3>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div
                                                className={`p-6 rounded-lg border-2 transition-all cursor-pointer
                                            ${formData.role.includes('content_creator')
                                                    ? 'border-[#e310cb] bg-purple-50'
                                                    : 'border-gray-200 hover:border-[#e310cb]'}`}
                                                onClick={() => handleRoleChange('content_creator')}
                                            >
                                                <div className="flex items-center gap-3">
                                                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center">
                                                        <CheckCircle2 className="text-white" size={24} />
                                                    </div>
                                                    <div>
                                                        <h4 className="font-semibold text-gray-900">Content Creator</h4>
                                                        <p className="text-sm text-gray-600">Share your content and set your rates</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={`p-6 rounded-lg border-2 transition-all cursor-pointer
                                            ${formData.role.includes('collaborator')
                                                    ? 'border-[#e310cb] bg-purple-50'
                                                    : 'border-gray-200 hover:border-[#e310cb]'}`}
                                                onClick={() => handleRoleChange('collaborator')}
                                            >
                                                <div className="flex items-center gap-3">
                                                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center">
                                                        <CheckCircle2 className="text-white" size={24} />
                                                    </div>
                                                    <div>
                                                        <h4 className="font-semibold text-gray-900">Collaborator</h4>
                                                        <p className="text-sm text-gray-600">Find and work with creators</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Conditional Sections with Improved UI */}
                                    {formData.role.map(role => (
                                        <div key={role} className="border-t border-gray-200 pt-6">
                                            {role === 'content_creator' && (
                                                <div className="bg-white rounded-lg">
                                                    <h3 className="text-xl font-semibold text-gray-800 mb-6">Content Creator
                                                        Preferences</h3>
                                                    <div className="space-y-6">
                                                        {formData.categories.map((cat, index) => {
                                                            // Filter categories to exclude already selected ones
                                                            const availableCategories = categories.filter(
                                                                category => !formData.categories.some(
                                                                    selectedCat => selectedCat.category === category.id && selectedCat !== cat
                                                                )
                                                            );

                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="p-6 bg-gray-50 rounded-lg border border-gray-300 hover:border-[#e310cb] shadow-sm hover:shadow-md transition-all"
                                                                >
                                                                    <div
                                                                        className="flex justify-between items-center mb-4">
                                                                        <h4 className="text-lg font-medium text-gray-800">Category {index + 1}</h4>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeCategory(index)}
                                                                            className="p-2 text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-full transition-all flex items-center justify-center shadow-sm hover:shadow-md"
                                                                            title="Remove Category"
                                                                        >
                                                                            <Trash2 size={16}/>
                                                                        </button>

                                                                    </div>

                                                                    <div
                                                                        className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                                        <div>
                                                                            <label
                                                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                                                Select Category
                                                                            </label>
                                                                            <select
                                                                                value={cat.category}
                                                                                onChange={(e) => updateCategory(index, 'category', e.target.value)}
                                                                                className="w-full h-6 rounded-lg border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-black"
                                                                            >
                                                                                <option value="" disabled hidden
                                                                                        style={{color: "black"}}>
                                                                                    Choose a category
                                                                                </option>
                                                                                {availableCategories.map(category => (
                                                                                    <option
                                                                                        key={category.id}
                                                                                        value={category.id}
                                                                                        style={{color: "black"}}
                                                                                    >
                                                                                        {category.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>

                                                                        <div>
                                                                            <label
                                                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                                                Charges (₹)
                                                                            </label>
                                                                            <input
                                                                                type="number"
                                                                                value={cat.charges}
                                                                                onChange={(e) => updateCategory(index, 'charges', e.target.value)}
                                                                                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-black"
                                                                                placeholder="Enter amount"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-6">
                                                                        <label
                                                                            className="block text-sm font-medium text-gray-700 mb-2">
                                                                            Demo Videos (optional)
                                                                        </label>
                                                                        <div className="space-y-3">
                                                                            {cat.demoVideos.map((video, videoIndex) => (
                                                                                <input
                                                                                    key={videoIndex}
                                                                                    type="url"
                                                                                    value={video}
                                                                                    onChange={(e) => updateCategory(index, 'demoVideos', e.target.value, videoIndex)}
                                                                                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-[#e310cb] focus:ring-[#e310cb] text-black"
                                                                                    placeholder="Enter video URL"
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    {/* Add Category Button at the End */}
                                                    <div className="flex justify-center mt-6">
                                                        <button
                                                            type="button"
                                                            onClick={addCategory}
                                                            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-[#4b0d49] to-[#e310cb] text-white rounded-lg hover:opacity-90 transition-opacity"
                                                        >
                                                            <Plus size={16}/>
                                                            Add Category
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {role === 'collaborator' && (
                                                <div className="bg-white rounded-lg ">
                                                    <h3 className="text-xl font-bold text-gray-800 mb-6">
                                                        Collaboration Categories
                                                        <span className="text-sm text-gray-500 ml-3">
                    Select categories and set your collaboration rates
                </span>
                                                    </h3>

                                                    <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                                                        {categories.map(category => {
                                                            const isSelected = selectedCategories.some(c => c.id === category.id);

                                                            return (
                                                                <div
                                                                    key={category.id}
                                                                    className={`relative flex flex-col items-center justify-center p-3 rounded-lg border-2 transition-all duration-300 cursor-pointer text-center 
                                ${isSelected
                                                                        ? 'border-[#e310cb] bg-purple-50 shadow-lg'
                                                                        : 'border-gray-200 hover:border-[#e310cb] hover:bg-gray-50'}`}
                                                                    onClick={() => toggleCategory(category)}
                                                                >
                                                                    {/* Category Selection Indicator */}
                                                                    {isSelected && (
                                                                        <div
                                                                            className="absolute top-2 right-2 bg-[#e310cb] text-white rounded-full p-1">
                                                                            <Check size={16}/>
                                                                        </div>
                                                                    )}

                                                                    {/* Category Content */}
                                                                    <div className="flex flex-col items-center">

                                                                            <img
                                                                                src={category.image}
                                                                                alt={category.name}
                                                                                className="w-12 h-12 object-contain mb-2"
                                                                            />
                                                                        </div>

                                                                        <h4 className="font-medium text-sm text-gray-900 mb-1">
                                                                            {category.name}
                                                                        </h4>


                                                                </div>
                                                            );
                                                        })}
                                                    </div>


                                                    {/* Selected Categories with Pricing */}
                                                    {selectedCategories.length > 0 && (
                                                        <div className="mt-6 space-y-3 ">
                                                            <h4 className="text-lg font-semibold text-gray-800 mb-3">
                                                                Set Collaboration Rates
                                                            </h4>
                                                            <div className="space-y-3">
                                                                {selectedCategories.map((category) => (
                                                                    <div
                                                                        key={category.id}
                                                                        className="bg-gray-100 rounded-md p-3 flex items-center gap-3 border border-gray-300 "
                                                                    >
                                                                        {/* Category Image */}
                                                                        <div className="flex-shrink-0 w-12 h-12 rounded-full bg-white border border-gray-200 flex items-center justify-center shadow-sm">
                                                                            <img
                                                                                src={category.image}
                                                                                alt={category.name}
                                                                                className="w-10 h-10 object-contain"
                                                                            />
                                                                        </div>

                                                                        {/* Category Name */}
                                                                        <div className="flex-grow">
                                                                            <h5 className="font-medium text-gray-900 text-sm">
                                                                                {category.name}
                                                                            </h5>
                                                                        </div>

                                                                        {/* Price Input */}
                                                                        <div className="flex items-center gap-1">
                                                                            <span className="text-gray-700 text-sm">₹</span>
                                                                            <input
                                                                                type="number"
                                                                                value={category.price}
                                                                                onChange={(e) =>
                                                                                    updateCategoryPrice(category.id, e.target.value)
                                                                                }
                                                                                placeholder="Rate"
                                                                                className="w-20 text-sm rounded-md border-gray-300 focus:border-[#e310cb] focus:ring-[#e310cb] bg-white text-black"
                                                                            />
                                                                        </div>

                                                                        {/* Remove Button */}
                                                                        <button
                                                                            onClick={() => toggleCategory(category)}
                                                                            className="text-gray-500 hover:text-red-500"
                                                                        >
                                                                            <Minus size={18} />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>

                                            )}
                                        </div>
                                    ))}


                                    {/* Submit Button with Loading State */}
                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            disabled={!acceptedTerms || isSubmitting}
                                            className={`w-full py-3 rounded-lg font-medium text-white flex items-center justify-center ${
                                                acceptedTerms && !isSubmitting
                                                    ? 'bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700'
                                                    : 'bg-gray-400 cursor-not-allowed'
                                            } transition-all`}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <Loader2 className="mr-2 animate-spin" size={20} />
                                                    Submitting...
                                                </>
                                            ) : (
                                                'Register as Content Creator'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {/* Keep existing form section */}
                        </>
                    )}








                </div>
            </div>
        </div>
    );
};

export default ContentCreatorForm;
