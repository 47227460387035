import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const ConnectButtons = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleInfluencerClick = () => {
        document.getElementById("footer")?.scrollIntoView({ behavior: "smooth" });
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
    };

    const navigate = useNavigate();

    return (
        <div id="join"
             className="flex flex-col items-center justify-center bg-gradient-to-r from-[#4b0d49] to-[#e310cb] h-96 text-white py-12 px-6 space-y-8">
            {/* Header Section */}
            <div className="text-center space-y-4 max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold">Join the Network of City Businesses & Creators</h1>
                <p className="text-lg text-gray-200">
                    Influencer or business? We've got you covered. Choose your role and start today!
                </p>

            </div>


            <div className="flex space-x-6">
                <button
                    className="px-8 py-3 bg-white text-[#4b0d49] rounded-lg font-semibold hover:bg-gray-200 transition duration-300 shadow-lg"
                    onClick={handleInfluencerClick}
                >
                    I'm an Influencer
                </button>

                <button
                    className="px-8 py-3 bg-[#ff17e4] text-white rounded-lg font-semibold hover:bg-[#ff5be7] transition duration-300 shadow-lg"
                    onClick={() => window.open('/collaborate', '_blank')}
                >
                    I'm a Business
                </button>
            </div>


            {/* Popup */}
            {showPopup && (
                <div
                    className="fixed bottom-12 left-1/2 transform -translate-x-1/2 bg-white text-[#4b0d49] px-6 py-2 rounded-lg shadow-lg">
                    <p className="font-bold">Contact Us</p>
                </div>
            )}
        </div>
    );
};

export default ConnectButtons;
