import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import './Faq.css';


const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className="faq-item" >
        <button className="faq-question-button" onClick={onClick}>
            <h3 className="faq-question">{question}</h3>
            <ChevronDown className={`faq-icon ${isOpen ? 'open' : ''}`} />
        </button>
        <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
            <div className="faq-answer-content">
                <p>{answer}</p>
            </div>
        </div>
    </div>
);

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const faqData = [
        {
            question: "What services do you offer for influencers and businesses?",
            answer: "We connect city influencers with city or national brands and vice versa, helping both parties collaborate effectively for sponsorships and promotions."
        },
        {
            question: "How does your platform help generate more revenue?",
            answer: "By acting as a mediator, we streamline partnerships and promotions, ensuring more revenue opportunities through efficient deals and broader connections."
        },
        {
            question: "What information do you collect from influencers?",
            answer: "We gather all your promotion price details and display them on our platform, making it easy for businesses to choose and connect with you."
        },
        {
            question: "What types of content categories do you support?",
            answer: "We support a variety of content categories, including food pages, real estate, cash hunt, and more, catering to a wide range of influencer niches."
        },
        {
            question: "I’m an influencer. How do I get started?",
            answer: "Simply contact us via our social media or the provided number to add your details to our platform. Once added, businesses can easily discover and collaborate with you."
        },
        {
            question: "I’m a business owner. How can I promote my brand through your platform?",
            answer: "If you’re a client looking to promote your brand, fill out the form on our website and select your desired category to connect with suitable influencers."
        },
        {
            question: "How does your platform ensure smooth collaborations?",
            answer: "We handle all communication, pricing, and deal-making processes, ensuring transparent and efficient collaborations between influencers and businesses."
        }
    ];


    return (
        <div className={"faq-container1 section-plum"}>
            <div className="faq-container2" id={"faq"}>
                <h2 className="faq-title">FAQ</h2>
                <div>
                    {faqData.map((item, index) => (
                        <FAQItem
                            key={index}
                            question={item.question}
                            answer={item.answer}
                            isOpen={index === openIndex}
                            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;