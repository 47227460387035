import React from 'react';
import '../App.css'; // Update with your new CSS file for the project

const NavBar = () => {
    return (
        <nav className="navbar">
            <ul className="navbar-list">
                <li className="navbar-item logo">
                    <h1>
                        <a href="/" className="logo-link">
                            <img src="/newlogo.png" alt="DubbiGo Logo" className="logo-image"/>
                            <a>ubbiGo</a>
                        </a>
                    </h1>
                </li>


                <li className="navbar-item">
                    <a href="#categories">Categories</a>
                </li>
                <li className="navbar-item">
                    <a href="#services">Services</a>
                </li>


                <li className="navbar-item">
                    <a href="#footer">Contact</a>
                </li>
                <li className="navbar-item">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dubbigo_">
                        <span className="fa-brands fa-instagram icon"></span>
                    </a>
                </li>
                {/*<li className="navbar-item">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@dubbigo">
                        <span className="fa-brands fa-youtube icon"></span>
                    </a>
                </li>*/}
                <li className="navbar-item">
                    <a className="button primary" href="#join">Join Us</a>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;
