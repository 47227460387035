import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from "./component/NavBar";
import IntroSection from "./component/IntroSection";
import BrandSection from "./component/BrandSection";
import ServicesSection from "./component/ServicesSection";
import TeamSection from "./component/TeamSection";
import Faq from "./component/Faq";
import Footer from "./component/Footer";
import ConnectButtons from "./component/ConnectButtons";
import CollaborationForm from "./component/CollaborationForm"; // Import the new component

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function AppContent() {
    const location = useLocation(); // Get the current location

    return (
        <div className="App">
            {/* Render NavBar only if the current path is not '/collaborate' */}
            {location.pathname !== '/collaborate' && <NavBar />}

            <Routes>
                {/* Main Page */}
                <Route
                    path="/"
                    element={
                        <>
                            <IntroSection/>
                            <ConnectButtons/>
                            <BrandSection/>
                            <ServicesSection/>
                            <TeamSection/>
                            <Faq/>
                            <Footer/>
                        </>
                    }
                />

                {/* Collaboration Form Page */}
                <Route path="/collaborate" element={<CollaborationForm/>} />
            </Routes>
        </div>
    );
}

export default App;
