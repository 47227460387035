import React from 'react';
import '../App.css';

const ServicesSection = () => {
    const services = [
        {
            icon: 'fa-solid fa-handshake',
            title: "Brand-Influencer Connect",
            description: "Bridge the gap between city businesses and influencers for impactful collaborations."
        },
        {
            icon: 'fa-solid fa-bullhorn',
            title: "Sponsorship Opportunities",
            description: "Help influencers secure promotions and sponsorships from city and national brands."
        },
        {
            icon: 'fa-solid fa-chart-line',
            title: "Campaign Analytics",
            description: "Provide data-driven insights to measure campaign performance and boost success."
        },
        {
            icon: 'fa-solid fa-users',
            title: "Influencer Onboarding",
            description: "Simplify onboarding to showcase influencer profiles and attract potential clients."
        },
        {
            icon: 'fa-solid fa-calculator',
            title: "Transparent Pricing Tools",
            description: "Enable influencers to share pricing for easy and efficient brand budgeting."
        },
        {
            icon: 'fa-solid fa-folder-open',
            title: "Category-Based Services",
            description: "Offer tailored solutions for niches like food, real estate, and lifestyle content."
        },
        {
            icon: 'fa-solid fa-thumbs-up',
            title: "Trusted Mediation",
            description: "Ensure smooth and transparent collaborations between brands and influencers."
        },
        {
            icon: 'fa-solid fa-map-marker-alt',
            title: "Local Campaigns",
            description: "Create city-focused campaigns to boost brand and influencer visibility locally."
        }
    ];


    return (
        <section id="services" className=" services-section section-dkblue bg-gradient-to-r from-[#4b0d49] to-[#e310cb]">
            <div className="container">
                <h2 className="section-title">Our Services</h2>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-item ">
                            <div className="service-icon">
                                <i className={service.icon}></i>
                            </div>
                            <h3 className="service-title">{service.title}</h3>
                            <p className="service-description">{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;